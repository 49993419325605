<template>
<div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <b-link class="brand-logo">
            <h2 class="brand-text text-primary ml-1">{{appName}}</h2>
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                <b-img fluid :src="imgUrl" alt="Login V2" />
            </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
            <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                    {{appName}} Hoş Geldin! 👋
                </b-card-title>
                <b-card-text class="mb-2">
                    Lütfen hesabınıza giriş yapın ve maceraya başlayın
                </b-card-text>

                <!-- form -->
                <validation-observer ref="loginValidation">
                    <b-form class="auth-login-form mt-2" @submit.prevent>
                        <!-- email -->
                        <b-form-group label="Giriş Bilgileri" label-for="login-email">
                            <b-form-input id="login-email" v-model="userEmail" name="login-email" placeholder="Kullanıcı Adı" />
                        </b-form-group>

                        <!-- forgot password -->
                        <b-form-group>
                            <div class="d-flex justify-content-between">
                                <label for="login-password">Şifren</label>
                                <!-- <b-link :to="{ name: 'auth-forgot-password-v2' }">
                                    <small>Şifreni mi unuttun?</small>
                                </b-link> -->
                            </div>
                            <validation-provider #default="{ errors }" name="Password" rules="required">
                                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                                    <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="············" />
                                    <b-input-group-append is-text>
                                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- checkbox -->
                        <b-form-group>
                            <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                                Beni Hatırla
                            </b-form-checkbox>
                        </b-form-group>

                        <!-- submit buttons -->
                        <b-button type="submit" variant="primary" block @click="validationForm">
                            Giriş Yap
                        </b-button>
                    </b-form>
                </validation-observer>

                <!-- divider -->
                <div class="divider my-2">
                    <div class="divider-text">v2.9.19</div>
                </div>

                <!-- social buttons -->
                <!-- <div class="auth-footer-btn d-flex justify-content-center">
                    <b-button variant="facebook" href="javascript:void(0)">
                        <feather-icon icon="FacebookIcon" />
                    </b-button>
                    <b-button variant="twitter" href="javascript:void(0)">
                        <feather-icon icon="TwitterIcon" />
                    </b-button>
                    <b-button variant="google" href="javascript:void(0)">
                        <feather-icon icon="MailIcon" />
                    </b-button>
                    <b-button variant="github" href="javascript:void(0)">
                        <feather-icon icon="GithubIcon" />
                    </b-button>
                </div> -->
            </b-col>
        </b-col>
        <!-- /Login-->
    </b-row>

    <b-modal v-model="showCode" centered hide-footer hide-header>
        <p class="text-center">
            <b>Lütfen Telefona Gelen Kodu Girin!</b>
        </p>
        <p>
            <b-form-input id="login-email" v-model="code" name="login-email" placeholder="Doğrulama Kodu" />
        </p>
        <p>
            <b-button type="submit" variant="primary" block @click="validCode">
                Doğrula
            </b-button>
        </p>
    </b-modal>

</div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BModal
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
        BModal
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: '',
            password: '',
            userEmail: '',
            sideImg: require('@/assets/images/pages/login-v2.svg'),
            // validation rulesimport store from '@/store/index'
            required,
            email,
            appName: $themeConfig.app.appName,
            showCode: false,
            code: '',
            secureKey: ''
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    methods: {
        validationForm() {
            this.$http.post("Auth/login", {
                loginKey: this.userEmail,
                password: this.password
            }).then((data) => {
                var responseData = data.data.data;

                if (responseData.token != "TwoFactory") {
                    localStorage.setItem("accessToken", responseData.token);
                    localStorage.setItem("userData", JSON.stringify(responseData.user));

                    if (responseData.user.userType == "BRANCHADMIN") {
                        if (!(responseData.hasOwnProperty("branch"))) {
                            responseData.user.branch = { menus: "0" };
                        } else {
                            responseData.user.branch = responseData.branch;

                        }
                        localStorage.setItem("userData", JSON.stringify(responseData.user));
                        this.$router.push({ name: 'home' });
                    } else {
                        this.$router.push({ name: 'home' });
                    }
                } else {
                    this.secureKey = responseData.user.idNumber;
                    this.showCode = true;
                }

            }).catch(error => {
                //console.log(error)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Kullanıcı Adı veya Şifre Hatalı',
                        icon: 'EditIcon',
                        variant: 'danger',
                    }
                }, { position: 'top-center' })
            })
        },
        validCode() {
            this.$http.post("Auth/two_factory", {
                loginKey: this.secureKey,
                password: this.code
            }).then((data) => {
                var responseData = data.data.data;

                localStorage.setItem("accessToken", responseData.token);
                localStorage.setItem("userData", JSON.stringify(responseData.user));

                if (responseData.user.userType == "BRANCHADMIN") {
                    if (!(responseData.hasOwnProperty("branch"))) {
                        responseData.user.branch = { menus: "0" };
                    } else {
                        responseData.user.branch = responseData.branch;

                    }
                    localStorage.setItem("userData", JSON.stringify(responseData.user));
                    this.$router.push({ name: 'home' });
                } else {
                    this.$router.push({ name: 'home' });
                }

            }).catch(error => {
                //console.log(error)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Kullanıcı Adı veya Şifre Hatalı',
                        icon: 'EditIcon',
                        variant: 'danger',
                    }
                }, { position: 'top-center' })
            })
        }
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
